@import './variables';

/****************** Button ********************/
.ant-btn-primary {
  box-shadow: none;
}

.ant-btn {
  height: 36px !important;
}

/****************** Form Input ********************/
.ant-form-item-optional {
  display: none !important;
}

.custom-input {
  padding: 10px 12px;
  font-size: 16px !important;
  line-height: 22px;
  color: #404040;
  background: transparent;

  & input.ant-input {
    background: transparent;
  }
}

.custom-date {
  padding: 10px 12px;
  font-size: 16px !important;
  line-height: 22px;
  color: #404040;
  width: 100% !important;
  background: transparent;

  & ant-picker {
    width: 100% !important;
  }
}

.custom-input-label .ant-form-item-label > label {
  height: 44px !important;
}

.custom-select {
  height: 44px;
  width: 100%;
  font-size: 16px !important;
  line-height: 28px;
  background: transparent !important;
}

.custom-input-number {
  padding: 4px 4px;
  font-size: 16px !important;
  line-height: 18px;
  width: 100%;
  color: #404040;
  background: transparent;

  & input.ant-input {
    background: transparent;
  }
}

.common-img {
  max-width: 100px;
  max-height: 100px;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  object-fit: cover;
  border: 2px solid #f8f8f8;
}

.btn-padding {
  padding-left: 24px !important;
  padding-right: 24px !important;
}

.custom-button {
  font-size: 1rem;
  font-weight: 600;
  width: 100%;
  height: 46px;
}

.content-wrapper {
  margin-top: 1.875rem;
}

.common-card-wrapper {
  padding: 30px 40px;
}

.card-wrapper {
  margin-bottom: 2rem;
}

.ant-input-number-disabled {
  padding: 10px 16px !important;
  font-size: 16px !important;
  line-height: 28px;
  color: #000 !important;
  border: 0 !important;
  background: #f8f8f8 !important;
}

.ant-input-disabled {
  padding: 13px 16px !important;
  font-size: 16px !important;
  line-height: 28px;
  color: #000 !important;
  border: 0 !important;
  background: #f8f8f8 !important;
}

.ant-picker-disabled {
  padding: 13px 16px !important;
  font-size: 16px !important;
  line-height: 28px;
  color: #000 !important;
  border: 0 !important;
  background: #f8f8f8 !important;

  & input {
    background: transparent;
    font-size: 16px !important;
    color: #000 !important;
  }
}

.ant-select-disabled {
  height: 54px;
  font-size: 16px !important;
  line-height: 28px;
  background: #f8f8f8 !important;

  .ant-select-selection-item {
    font-size: 16px;
    color: #000;
  }

  .ant-select-selector {
    border: 0 !important;
    background: #f8f8f8 !important;
  }

  .ant-select-arrow {
    display: none;
  }
}

/****************** Error Page ********************/

.error-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3.75rem 2rem;
  border-radius: 8px;
  background-color: #fff;

  .img-fluid {
    max-width: 100%;
    height: auto;
    vertical-align: middle;
  }

  .error-heading {
    font-size: 4rem;
    font-weight: 600;
    margin-top: 2.25rem;
    margin-bottom: 2.25rem;
    color: #404040;

    @media only screen and (width <= 1280px) {
      font-size: 3rem;
      margin-bottom: 1.75rem;
    }

    @media only screen and (width <= 768px) {
      font-size: 2rem;
      margin-bottom: 1rem;
    }
  }

  .error-subheading {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 1.8rem;
    color: #404040;

    @media only screen and (width <= 1280px) {
      font-size: 1.12rem;
      margin-bottom: 1.45rem;
    }

    @media only screen and (width <= 768px) {
      font-size: 0.875rem;
      margin-bottom: 1.25rem;
    }
  }

  .ant-btn-link {
    color: #003773;
    font-weight: 600;
    font-size: 16px;
  }
}

/****************** Loader ********************/

.loader-wrapper {
  background: rgb(0 0 0 / 70%);
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 10000;
}

/****************** Form ********************/

.form-wrapper {
  padding: 3rem 2.5rem;
  width: 32rem;

  // overflow: auto;
  background-color: #fff;
  border-radius: 7px;

  @media only screen and (width <= 768px) {
    width: 21.75rem;
  }

  @media only screen and (width <= 360px) {
    padding: 2.5rem 1.25rem;
    width: 16.75rem;
  }
}

.form-title {
  color: #01509a;
  font-size: 1.625rem;
  font-weight: 800;

  @media only screen and (width <= 1280px) {
    margin-bottom: 0.9375rem;
    font-size: 1.625rem;
    font-weight: 800;
    line-height: 2.125rem;
  }

  @media only screen and (width <= 768px) {
    margin-bottom: 0.875rem;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1.9375rem;
  }

  @media only screen and (width <= 360px) {
    margin-bottom: 0.625rem;
    font-size: 1.125rem;
    font-weight: 700;
    line-height: 1.5625rem;
  }
}

.login-description {
  margin-bottom: 1.875rem;
  color: #404040;
  font-size: 0.875rem;

  @media only screen and (width <= 1280px) {
    margin-bottom: 1.875rem;
  }

  @media only screen and (width <= 768px) {
    margin-bottom: 1.75rem;
    font-weight: 400;
  }

  @media only screen and (width <= 360px) {
    margin-bottom: 1.5625rem;
    font-weight: 700;
  }
}

.remeber-me-text {
  color: #01509a;
  font-size: 0.875rem;
}

.action-wrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.5rem;
}

/****************** Top Header User ********************/

.profile-overlay-wrapper {
  width: 10rem;
  padding: 15px;
}

.profile-overlay-text a {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #003773;
  font-size: 1rem;
  font-weight: 600;

  & > a {
    display: block;
  }

  @media only screen and (width <= 786px) {
    font-size: 0.875rem;
  }
}

.item-divider {
  margin: 16px 0 !important;
}

.user-detail-title {
  padding: 0 10px;
  font-size: 16px;
  font-weight: 600;
}
